import React from 'react';
import './Wrapper.css'
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import MobileHeader from "../MobileHeader/MobileHeader";

const Wrapper = ({children}) => {
    return (
        <div className="wrapper">
            <Header/>
            <MobileHeader/>
                {children}
            <Footer/>
        </div>
    );
};

export default Wrapper;