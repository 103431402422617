import React from 'react';
import Burger from '../../Assets/Img/menu-option.svg';
import Search from '../../Assets/Img/search.svg';
import './MobileHeader.css'

const MobileHeader = () => {
    return (
        <div className="mobile-header"><span style={{backgroundImage: `url(${Burger})`}} className="mobile-header-menu"></span>
            <div className="mobile-header-logo">Announcement</div>
            <div className="mobile-header-search-container">
                <div className="search-input-root ">
                    <div className="search-input-container">
                        <div className="aa-Autocomplete">

                            <div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="icon-container">
                    <img src={Search} alt="search" className="search-icon"/>
                </div>
            </div>
        </div>
    );
};

export default MobileHeader;