import React from 'react';
import Logo from '../../Assets/Img/logo.svg';
import {SearchOutlined} from '@ant-design/icons';
import './Header.css';

const Header = () => {
    return (<header className="header">
            <div className="header-left">
                {/* Logo */}
                <div className="logo">
                    <img src={Logo} alt="ByBit Logo"/>
                </div>
                {/* Links */}
                <nav className="nav-links">
                    <div className="nav-item">
                        <a href="#" className="nav-link"><span className="nav-text">Buy Crypto</span></a>
                    </div>
                    <div className="nav-item">
                        <a href="#" className="nav-link"><span className="nav-text">Spot</span></a>
                    </div>
                    <div className="nav-item">
                        <a href="#" className="nav-link"><span className="nav-text">Derivatives</span></a>
                    </div>
                    <div className="nav-item">
                        <a href="#" className="nav-link"><span className="nav-text">Web3</span></a>
                    </div>
                </nav>
                <span className="search-bar-container">
                 <div className="search-container">
                        <div className="search-icon"><SearchOutlined style={{color: "white", fontSize: "16px"}}/></div>
                            <input type="text" className="search-input" placeholder="Search all articles"/>
                        </div>
                </span>
            </div>


            <div className="header-right">
                <div className="login-link">
                    <a href="#">Log In</a>
                </div>
                <div className="signup-link">
                    <a href="#">Sign Up</a>
                </div>
                <a className="header-btn" href="#">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="u:download-alt">
                            <path id="Vector"
                                  d="M8.29 13.29C8.19627 13.383 8.12188 13.4936 8.07111 13.6154C8.02034 13.7373 7.9942 13.868 7.9942 14C7.9942 14.132 8.02034 14.2627 8.07111 14.3846C8.12188 14.5064 8.19627 14.617 8.29 14.71L11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L15.71 14.71C15.8983 14.5217 16.0041 14.2663 16.0041 14C16.0041 13.7337 15.8983 13.4783 15.71 13.29C15.5217 13.1017 15.2663 12.9959 15 12.9959C14.7337 12.9959 14.4783 13.1017 14.29 13.29L13 14.59V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V14.59L9.71 13.29C9.61704 13.1963 9.50644 13.1219 9.38458 13.0711C9.26272 13.0203 9.13201 12.9942 9 12.9942C8.86799 12.9942 8.73728 13.0203 8.61542 13.0711C8.49356 13.1219 8.38296 13.1963 8.29 13.29ZM18 9H16C15.7348 9 15.4804 9.10536 15.2929 9.29289C15.1054 9.48043 15 9.73478 15 10C15 10.2652 15.1054 10.5196 15.2929 10.7071C15.4804 10.8946 15.7348 11 16 11H18C18.2652 11 18.5196 11.1054 18.7071 11.2929C18.8946 11.4804 19 11.7348 19 12V19C19 19.2652 18.8946 19.5196 18.7071 19.7071C18.5196 19.8946 18.2652 20 18 20H6C5.73478 20 5.48043 19.8946 5.29289 19.7071C5.10536 19.5196 5 19.2652 5 19V12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11H8C8.26522 11 8.51957 10.8946 8.70711 10.7071C8.89464 10.5196 9 10.2652 9 10C9 9.73478 8.89464 9.48043 8.70711 9.29289C8.51957 9.10536 8.26522 9 8 9H6C5.20435 9 4.44129 9.31607 3.87868 9.87868C3.31607 10.4413 3 11.2044 3 12V19C3 19.7956 3.31607 20.5587 3.87868 21.1213C4.44129 21.6839 5.20435 22 6 22H18C18.7956 22 19.5587 21.6839 20.1213 21.1213C20.6839 20.5587 21 19.7956 21 19V12C21 11.2044 20.6839 10.4413 20.1213 9.87868C19.5587 9.31607 18.7956 9 18 9Z"
                                  />
                        </g>
                    </svg>

                </a>
                <a className="header-btn" href="#">
                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M9.52 4.392A8.009 8.009 0 0 0 4.061 11h2.997c.227-2.363 1.078-4.627 2.46-6.608Zm4.96 0c1.383 1.981 2.234 4.245 2.461 6.608h2.997a8.009 8.009 0 0 0-5.457-6.608ZM14.93 11c-.269-2.373-1.28-4.642-2.93-6.54C10.35 6.357 9.339 8.626 9.07 11h5.86Zm-5.86 2h5.86c-.269 2.373-1.28 4.642-2.93 6.54-1.65-1.898-2.661-4.167-2.93-6.54Zm-2.011 0H4.062a8.009 8.009 0 0 0 5.457 6.608C8.137 17.627 7.286 15.363 7.06 13Zm7.422 6.608c1.382-1.981 2.233-4.245 2.46-6.608h2.997a8.008 8.008 0 0 1-5.457 6.608ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z"
                              ></path>
                    </svg>
                </a>
            </div>
        </header>);
};

export default Header;
