import React from 'react';
import Wrapper from "./components/Wrapper/Wrapper";
import Img from "./Assets/Img/img.jpg"
import Share from "./Assets/Img/share.svg"
import './App.css'

const App = () => {
    return (<Wrapper>
            <div className="page-article-header-wrap">
                <div className="page-article-header">
                    <h1 className="main-title">Announcement</h1>
                    <h2 className="description">Be in the know on the latest happenings at Bybit вЂ” new products, our
                        latest trading pairs and more!
                    </h2>
                </div>
            </div>
            <div className="announcement-contentstack-layout">
                <div className="announcement-contentstack-layout-children">
                    <div id="scroll-up" className="scroll-up" style={{visibility: "hidden"}}><i className="arrow-up"></i>
                    </div>
                    <div
                        style={{position: "absolute", top: "-1e+06px", left: "-100000px", width: "1px", height: "1px", overflow: "hidden;"}}>
                        <a href="https://announcements.bybit.com/linkmap/">roadmap</a></div>
                    <div className="ant-row page-article-content undefined">
                        <div className="ant-col ant-col-24 page-article-content-menu-ctn ant-col-lg-6">
                            <ul className="ant-menu ant-menu-root ant-menu-vertical ant-menu-light page-article-content-menu"
                                role="menu" tabIndex="0" data-menu-list="true">
                                <li className="ant-menu-item ant-menu-item-selected ant-menu-item-only-child"
                                    role="menuitem" tabIndex="-1" data-cy="allAnnouncements"
                                    data-spm-even="AnnounceLeftSideBarClick"
                                    data-spm-data="{&quot;category_name&quot;:&quot;All&quot;,&quot;category_position&quot;:1,&quot;page_language&quot;:&quot;en&quot;}"
                                    data-menu-id="rc-menu-uuid-50751-2-"><span className="ant-menu-title-content"><a
                                    href="https://www.bybit.com/en">ALL</a></span></li>
                                <li className="ant-menu-item ant-menu-item-only-child" role="menuitem" tabIndex="-1"
                                    data-cy="newCrypto" data-spm-even="AnnounceLeftSideBarClick"
                                    data-spm-data="{&quot;category_name&quot;:&quot;New Listings&quot;,&quot;category_position&quot;:2,&quot;page_language&quot;:&quot;en&quot;}"
                                    data-menu-id="rc-menu-uuid-50751-2-new_crypto"><span
                                    className="ant-menu-title-content"><a
                                    href="https://www.bybit.com/en">New Listings</a></span></li>
                                <li className="ant-menu-item ant-menu-item-only-child" role="menuitem" tabIndex="-1"
                                    data-cy="latestActivities" data-spm-even="AnnounceLeftSideBarClick"
                                    data-spm-data="{&quot;category_name&quot;:&quot;Latest Activities&quot;,&quot;category_position&quot;:3,&quot;page_language&quot;:&quot;en&quot;}"
                                    data-menu-id="rc-menu-uuid-50751-2-latest_activities"><span
                                    className="ant-menu-title-content"><a href="https://www.bybit.com/en">Latest Activities</a></span>
                                </li>
                                <li className="ant-menu-item ant-menu-item-only-child" role="menuitem" tabIndex="-1"
                                    data-cy="latestBybitNews" data-spm-even="AnnounceLeftSideBarClick"
                                    data-spm-data="{&quot;category_name&quot;:&quot;Latest Bybit News&quot;,&quot;category_position&quot;:4,&quot;page_language&quot;:&quot;en&quot;}"
                                    data-menu-id="rc-menu-uuid-50751-2-latest_bybit_news"><span
                                    className="ant-menu-title-content"><a href="https://www.bybit.com/en">Latest Bybit News</a></span>
                                </li>
                                <li className="ant-menu-item ant-menu-item-only-child" role="menuitem" tabIndex="-1"
                                    data-cy="productUpdates" data-spm-even="AnnounceLeftSideBarClick"
                                    data-spm-data="{&quot;category_name&quot;:&quot;Products Updates&quot;,&quot;category_position&quot;:5,&quot;page_language&quot;:&quot;en&quot;}"
                                    data-menu-id="rc-menu-uuid-50751-2-Partnership_Announcement"><span
                                    className="ant-menu-title-content"><a href="https://www.bybit.com/en">Partnership Announcement</a></span>
                                </li>
                                <li className="ant-menu-item ant-menu-item-only-child" role="menuitem" tabIndex="-1"
                                    data-cy="newFiatListings" data-spm-even="AnnounceLeftSideBarClick"
                                    data-spm-data="{&quot;category_name&quot;:&quot;New Fiat Listings&quot;,&quot;category_position&quot;:6,&quot;page_language&quot;:&quot;en&quot;}"
                                    data-menu-id="rc-menu-uuid-50751-2-Web3"><span className="ant-menu-title-content"><a
                                    href="https://www.bybit.com/en">Web3</a></span></li>
                                <li className="ant-menu-item ant-menu-item-only-child" role="menuitem" tabIndex="-1"
                                    data-cy="maintenanceUpdates" data-spm-even="AnnounceLeftSideBarClick"
                                    data-spm-data="{&quot;category_name&quot;:&quot;Maintenance Updates&quot;,&quot;category_position&quot;:7,&quot;page_language&quot;:&quot;en&quot;}"
                                    data-menu-id="rc-menu-uuid-50751-2-maintenance_updates"><span
                                    className="ant-menu-title-content"><a href="https://www.bybit.com/en">Maintenance Updates</a></span>
                                </li>
                                <li className="ant-menu-item ant-menu-item-only-child" role="menuitem" tabIndex="-1"
                                    data-cy="delistings" data-spm-even="AnnounceLeftSideBarClick"
                                    data-spm-data="{&quot;category_name&quot;:&quot;Delistings&quot;,&quot;category_position&quot;:8,&quot;page_language&quot;:&quot;en&quot;}"
                                    data-menu-id="rc-menu-uuid-50751-2-delistings"><span
                                    className="ant-menu-title-content"><a href="https://www.bybit.com/en">Delistings</a></span>
                                </li>
                                <li className="ant-menu-item ant-menu-item-only-child" role="menuitem" tabIndex="-1"
                                    data-cy="otherAnnouncements" data-spm-even="AnnounceLeftSideBarClick"
                                    data-spm-data="{&quot;category_name&quot;:&quot;More&quot;,&quot;category_position&quot;:9,&quot;page_language&quot;:&quot;en&quot;}"
                                    data-menu-id="rc-menu-uuid-50751-2-other"><span
                                    className="ant-menu-title-content"><a
                                    href="https://www.bybit.com/en">More</a></span></li>
                            </ul>
                            <div aria-hidden="true" style={{display: "none"}}></div>
                        </div>
                        <div className="ant-col ant-col-24 page-article-content-announcement ant-col-lg-18">
                            <div  className="article-detail-wrapper">
                                <nav className="ant-breadcrumb article-detail-breadcrumb">
                                    <ol>
                                        <li><a className="ant-breadcrumb-link"
                                               href="https://announcements.bybit.com/en/">Announcement</a><span
                                            className="ant-breadcrumb-separator">&gt;</span></li>
                                        <li><a className="ant-breadcrumb-link"
                                               href="https://announcements.bybit.com/en/?category=">ALL</a><span
                                            className="ant-breadcrumb-separator">&gt;</span></li>
                                        <li><a className="current-page"
                                               href="https://announcements.bybit.com/article/new-listing-next-usdt-grab-a-share-of-the-60-000-usdt-prize-pool--bltcd5442174a2fb29e/#">Current
                                            Page</a><span className="ant-breadcrumb-separator">&gt;</span></li>
                                    </ol>
                                </nav>
                                <div className="article-detail" id="article-detail">
                                    <div className="article-detail-title">New Listing: MEGAETH/USDT</div>
                                    <div className="ant-row ant-row-space-between ant-row-middle">
                                        <div className="ant-col ant-col-20">
                                            <span className="article-detail-date">Oct 7, 2024</span>
                                            <span className="article-detail-tags">
                                            <span className="article-detail-tag" data-cy="tag-0" data-spm-even="AnnounceArticleTagClick" data-spm-data="{&quot;article_tag_type&quot;:&quot;Spot&quot;}">Spot</span>
                                                <span className="article-detail-tag" data-cy="tag-1" data-spm-even="AnnounceArticleTagClick" data-spm-data="{&quot;article_tag_type&quot;:&quot;Spot Listings&quot;}">Spot Listings</span>
                                                <span className="article-detail-tag" data-cy="tag-2" data-spm-even="AnnounceArticleTagClick" data-spm-data="{&quot;article_tag_type&quot;:&quot;Trading Bots&quot;}">Trading Bots</span></span>
                                        </div>
                                        <div className="social-share">
                                            <div className="ant-dropdown-trigger more-icon"><img src={Share}
                                                                                                 alt="share"/></div>
                                        </div>
                                    </div>
                                    <div className="article-detail-content" id="article-detail-content">
                                        <div className="embedded-asset"><img src={Img} alt="img"/></div>
                                        <p></p>
                                        <p>Bybit is excited to announce the upcoming listing of  <b>MEGAETH</b> on our <b>Spot trading</b> platform!
                                        </p>
                                        <p></p>
                                        <h3><strong>Listing Timeline</strong></h3>
                                        <ul>
                                            <li><strong>Deposit Opens: </strong>Oct 15, 2024, 10AM UTC</li>
                                            <li><strong>Lisiting: </strong>Oct 15, 2024, 1PM UTC</li>
                                            <li><strong>Withdrawal Opens: </strong>Oct 16, 2024, 10AM UTC</li>
                                        </ul>
                                        <p></p>
                                        <p>Deposits and withdrawals will be available via the Ethereum network.</p>
                                        <p></p>

                                        <h3><strong>MegaETH is available for purchase on Pre-Market right now!</strong>
                                        </h3>
                                        <p></p>
                                        <p>
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                                 style={{width: "20px", marginRight: "7px", height: "20px",display: "inline", marginBottom: "-4px"}} viewBox="0 0 122.88 121">
                                                <defs>
                                                    <style>{`.cls-1{fill:#ef4136;}.cls-1,.cls-3,.cls-4,.cls-6{fill - rule:evenodd;}.cls-2{fill:gray;}.cls-3{fill:#fff;}.cls-4{fill:#c72b20;}.cls-5,.cls-6{fill:#1a1a1a;}`}</style>
                                                </defs>
                                                <title>calendar-color</title>
                                                <path className="cls-1"
                                                      d="M11.52,6.67h99.84a11.57,11.57,0,0,1,11.52,11.52V44.94H0V18.19A11.56,11.56,0,0,1,11.52,6.67Zm24.79,9.75A9.31,9.31,0,1,1,27,25.73a9.31,9.31,0,0,1,9.31-9.31Zm49.79,0a9.31,9.31,0,1,1-9.31,9.31,9.31,9.31,0,0,1,9.31-9.31Z"></path>
                                                <path className="cls-2"
                                                      d="M111.36,121H11.52A11.57,11.57,0,0,1,0,109.48V40H122.88v69.46A11.56,11.56,0,0,1,111.36,121Z"></path>
                                                <path className="cls-3"
                                                      d="M12.75,117.31h97.38a9.1,9.1,0,0,0,9.06-9.06V40H3.69v68.23a9.09,9.09,0,0,0,9.06,9.06Z"></path>
                                                <path className="cls-4"
                                                      d="M86.1,14.63a11.11,11.11,0,1,1-7.85,3.26l.11-.1a11.06,11.06,0,0,1,7.74-3.16Zm0,1.79a9.31,9.31,0,1,1-9.31,9.31,9.31,9.31,0,0,1,9.31-9.31Z"></path>
                                                <path className="cls-4"
                                                      d="M36.31,14.63a11.11,11.11,0,1,1-7.85,3.26l.11-.1a11.08,11.08,0,0,1,7.74-3.16Zm0,1.79A9.31,9.31,0,1,1,27,25.73a9.31,9.31,0,0,1,9.31-9.31Z"></path>
                                                <path className="cls-5"
                                                      d="M80.54,4.56C80.54,2,83,0,86.1,0s5.56,2,5.56,4.56V25.77c0,2.51-2.48,4.56-5.56,4.56s-5.56-2-5.56-4.56V4.56Z"></path>
                                                <path className="cls-5"
                                                      d="M30.75,4.56C30.75,2,33.24,0,36.31,0s5.56,2,5.56,4.56V25.77c0,2.51-2.48,4.56-5.56,4.56s-5.56-2-5.56-4.56V4.56Z"></path>
                                                <path className="cls-6"
                                                      d="M22,85.62H36a1.79,1.79,0,0,1,1.79,1.79v11.7A1.8,1.8,0,0,1,36,100.9H22a1.8,1.8,0,0,1-1.8-1.79V87.41A1.8,1.8,0,0,1,22,85.62Z"></path>
                                                <path className="cls-6"
                                                      d="M54.58,85.62H68.64a1.79,1.79,0,0,1,1.79,1.79v11.7a1.8,1.8,0,0,1-1.79,1.79H54.58a1.8,1.8,0,0,1-1.79-1.79V87.41a1.8,1.8,0,0,1,1.79-1.79Z"></path>
                                                <path className="cls-6"
                                                      d="M86.87,85.62h14.06a1.8,1.8,0,0,1,1.79,1.79v11.7a1.8,1.8,0,0,1-1.79,1.79H86.87a1.8,1.8,0,0,1-1.79-1.79V87.41a1.79,1.79,0,0,1,1.79-1.79Z"></path>
                                                <path className="cls-6"
                                                      d="M22,56.42H36a1.8,1.8,0,0,1,1.79,1.8V69.91A1.8,1.8,0,0,1,36,71.7H22a1.8,1.8,0,0,1-1.8-1.79V58.22a1.81,1.81,0,0,1,1.8-1.8Z"></path>
                                                <path className="cls-6"
                                                      d="M54.58,56.42H68.64a1.8,1.8,0,0,1,1.79,1.8V69.91a1.8,1.8,0,0,1-1.79,1.79H54.58a1.79,1.79,0,0,1-1.79-1.79V58.22a1.8,1.8,0,0,1,1.79-1.8Z"></path>
                                                <path className="cls-6"
                                                      d="M86.87,56.42h14.06a1.8,1.8,0,0,1,1.79,1.8V69.91a1.8,1.8,0,0,1-1.79,1.79H86.87a1.79,1.79,0,0,1-1.79-1.79V58.22a1.8,1.8,0,0,1,1.79-1.8Z"></path>
                                            </svg>
                                            <span><strong>Event period:</strong> Oct 15, 2024 – Oct 16, 2024, 11:59PM UTC</span>
                                        </p>
                                        <p></p>
                                        <p><strong>MegaETH token contract:</strong></p>
                                        <p style={{wordBreak: "break-all"}}>0x8BA0a028038fc8A2B4A457Ef4bAC82c157dad83f</p>
                                        <p></p>
                                        <p>
                                            The future of Ethereum is already here! MegaETH is the secure zkEVM ecosystem that empowers dapps to thrive.
                                        </p>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>);
};

export default App;