import React from 'react';
import Facebook from '../../Assets/Img/social/facebook.svg';
import Instagram from '../../Assets/Img/social/inst.svg';
import Twitter from '../../Assets/Img/social/twitter.svg';
import Youtube from '../../Assets/Img/social/youtube.svg';
import Tiktok from '../../Assets/Img/social/tiktok.svg';
import Linkedin from '../../Assets/Img/social/LinkedIn.svg';
import Telegram from '../../Assets/Img/social/telegram.svg';
import Reddit from '../../Assets/Img/social/Reddit.svg';
import Medium from '../../Assets/Img/social/Medium.svg';
import Logo from '../../Assets/Img/social/logo-white.svg';
import Qr from '../../Assets/Img/social/download-qrcode.png';

import './Footer.css'

const Footer = () => {
    return (
        <div className="blog-footer">
            <div className="blog-footer-upper">
                <div className="blog-footer-left">
                    <p className="intro">Be the first to get critical insights and analysis of the crypto world,
                        subscribe now to our newsletter.</p>
                    <div className="subscribe">
                        <input placeholder="Enter your email address" className="ant-input subscribe-input" type="text"
                               value=""/>
                        <button type="button" className="ant-btn ant-btn-default announcement-button subscribe-button">
                            <span>Subscribe</span></button>
                    </div>
                    <div className="socials"><span className="social-guide">Follow Us</span>
                        <div className="social">
                            <a style={{backgroundImage: `url(${Facebook})`}} rel="noreferrer" target="_blank" className="icon Facebook"
                               href="https://www.facebook.com/Bybit"></a>
                            <a style={{backgroundImage: `url(${Twitter})`}} rel="noreferrer" target="_blank" className="icon Twitter"
                               href="https://twitter.com/Bybit_Official"></a>
                            <a style={{backgroundImage: `url(${Instagram})`}} rel="noreferrer" target="_blank" className="icon Instagram"
                               href="https://www.instagram.com/bybit_official/"></a>
                            <a style={{backgroundImage: `url(${Youtube})`}} rel="noreferrer" target="_blank" className="icon YouTube"
                               href="https://www.youtube.com/c/Bybit"></a>
                            <a style={{backgroundImage: `url(${Linkedin})`}} rel="noreferrer" target="_blank" className="icon LinkedIn"
                               href="https://www.linkedin.com/company/bybitexchange/"></a>
                            <a style={{backgroundImage: `url(${Telegram})`}} rel="noreferrer" target="_blank" className="icon Telegram"
                               href="https://t.me/BybitEnglish"></a>
                            <a style={{backgroundImage: `url(${Tiktok})`}} rel="noreferrer" target="_blank" className="icon TikTok"
                               href="https://www.tiktok.com/@bybit_official"></a>
                            <a style={{backgroundImage: `url(${Reddit})`}} rel="noreferrer" target="_blank" className="icon Reddit"
                               href="https://www.reddit.com/r/Bybit/"></a>
                            <a style={{backgroundImage: `url(${Medium})`}} rel="noreferrer" target="_blank" className="icon Medium"
                               href="https://medium.com/bybit"></a>
                        </div>
                    </div>
                    <p className="copyright">В© 2018-2024 Bybit.com. All rights reserved.</p>
                </div>
                <div className="blog-footer-right">
                    <div className="blog-footer-logo"><span style={{backgroundImage: `url(${Logo})`}} className="logo"></span></div>
                    <div className="blog-footer-qr-container"><img src={Qr} alt="QR"
                                                                   className="blog-footer-qr"/>
                        <div>
                            <p className="blog-footer-qr-title">Trade Anytime, Anywhere!</p>
                            <p className="blog-footer-qr-text">Scan code to download APP</p>
                        </div>
                    </div>
                    <div className="links"><span><a className="link"
                                                    href="https://www.bybit.com/app/terms-service/information">Terms of Service</a><span> | </span></span><span><a
                        className="link" href="https://www.bybit.com/app/terms-service/privacyPolicy">Privacy Policy</a><span> | </span></span><span><a
                        className="link" href="https://blog.bybit.com/feed/">RSS Feed</a><span></span></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;